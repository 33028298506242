import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ReactGA from "react-ga4";
import {Button, Col, Row} from "react-bootstrap";
import ShopGender from "../../components/product/ShopGender";
import { parseCurrentQuery } from "../shop/ShopGridFullWidth";
import {useHistory} from "react-router";
import ShopMotorPref from "../../components/product/ShopMotorPref";
import ShopPain from "../../components/product/ShopPain";
import qs from "query-string";
import ShopShoeType from "../../components/product/ShopShoeType";
import ShopStrideAnalysis from "../../components/product/ShopStrideAnalysis";
import ShopShoeUsage from "../../components/product/ShopShoeUsage";
import ShopSize from "../../components/product/ShopSize";
import { getAvailablesSizes, getUniqueShoesSizeByGender } from "../shop-product/tools";
import ShopCompleteSize from "../../components/product/ShopCompleteSize";
import ShopShoeDistance from "../../components/product/ShopShoeDistance";
import ShopIsPain from "../../components/product/ShopIsPain";
import ShopIsMotorPref from "../../components/product/ShopIsMotorPref";

export function calculateShoeType(motorPref, painType, isPain, isMotor){
  var array = []
  if (motorPref && (painType || isPain)){
    if(motorPref == "Terrien en cuisse" || motorPref == "Terrien en pied"){
      if (painType ==  "Douleur chaîne antérieure" || painType == "Douleur genou-hanche-dos"){
        array = ["Avec drop réduit", "Avec drop haut"]
      } else if (isPain ==  "Non"){
        array = ["Coureur débutant", "Coureur régulier", "Axé performance"]
      }
    } else if(motorPref == "Aérien en cuisse" || motorPref == "Aérien en pied"){
      if (painType ==  "Douleur chaîne antérieure" || painType == "Douleur genou-hanche-dos"){
        array = ["Avec drop réduit", "Avec drop haut"]
      } else if (isPain ==  "Non"){
        array = ["Avec drop réduit", "Avec drop haut", "Axé performance"]
      }
    }
  } else if (!motorPref && isMotor && isMotor == "Non"){
    if (painType ==  "Douleur chaîne antérieure" || painType == "Douleur genou-hanche-dos"){
      array = ["Avec drop réduit", "Avec drop haut"]
    } else if (isPain ==  "Non"){
      array = ["Avec drop réduit", "Avec drop haut", "Axé performance"]
    }
  }
  
  return array
}

const ProResearchHomePage = ({ location }) => {
  const { pathname } = location;
  const history = useHistory();

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
});
  const [currentQuery, setCurrentQuery] = useState(query)
  const [genderSortValue, setGenderSortValue] = useState('');
  const [genderSortType, setGenderSortType] = useState('');
  const [motorSortValue, setMotorSortValue] = useState('');
  const [motorSortType, setMotorSortType] = useState('');
  const [isMotorSortValue, setIsMotorSortValue] = useState('');
  const [isMotorSortType, setIsMotorSortType] = useState('');
  const [painSortValue, setPainSortValue] = useState('');
  const [painSortType, setPainSortType] = useState('');
  const [isPainSortValue, setIsPainSortValue] = useState('');
  const [isPainSortType, setIsPainSortType] = useState('');
  const [shoeTypeSortValue, setShoeTypeSortValue] = useState('');
  const [shoeTypeSortType, setShoeTypeSortType] = useState('');
  const [strideAnalysisSortValue, setStrideAnalysisSortValue] = useState('');
  const [strideAnalysisSortType, setStrideAnalysisSortType] = useState('');
  const [usageSortValue, setUsageSortValue] = useState('');
  const [usageSortType, setUsageSortType] = useState('');
  const [distanceSortValue, setDistanceSortValue] = useState('');
  const [distanceSortType, setDistanceSortType] = useState('');
  const [currentShoeType, setCurrentShoeType] = useState([])
  const [isPerformanceShoe, setIsPerformanceShoe] = useState(false)
  const [sizeSortValue, setSizeSortValue] = useState('');

  const handleClick = (pathname, currentQuery) => history.push({
    pathname: pathname,
    search: currentQuery
  });

  useEffect(() => {
      if (currentQuery) {
        setCurrentShoeType(calculateShoeType(currentQuery.motor, currentQuery.pains, currentQuery.isPain, currentQuery.isMotor));
        handleClick(pathname, parseCurrentQuery(currentQuery));
        if(currentQuery.types == "Axé performance"){
          setIsPerformanceShoe(true)
        } else {
          setIsPerformanceShoe(false)
        }
      } else {
        setIsPerformanceShoe(false)
        setCurrentQuery([])
      }
  }, [currentQuery]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [])

  const handleSearch = (currentQuery) => {
    let query =  "/products/shoes?page=1"

    query = query + "&genders=" + currentQuery.genders
    query = query + "&pains=" + currentQuery.pains
    query = query + "&isPain=" + currentQuery.isPain
    query = query + "&isMotor=" + currentQuery.isMotor
    if (currentQuery.motor){
      query = query + "&motor=" + currentQuery.motor
    } 
    if (currentQuery.types){
      query = query + "&types=" + currentQuery.types
    }    
    if (currentQuery.analysis){
      query = query + "&analysis=" + currentQuery.analysis
    }
    if (currentQuery.shoeType){
      query = query + "&shoeType=" + currentQuery.shoeType
    }
    if (currentQuery.shoeDistance){
      query = query + "&shoeDistance=" + currentQuery.shoeDistance
    }

    window.location.href = query
  }

  return (
    <Fragment>
      <MetaTags>
        <title>Recherche</title>
        <meta
          name="description"
          content="The Running Collective."
        />
        <meta name="robots" content="noindex, nofollow"/>
      </MetaTags>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Accueil</BreadcrumbsItem>
      <LayoutOne
          headerContainerClass="container-fluid"
          headerPaddingClass="header-padding-2"
          headerTop="visible"
      location={location}>
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="error-area pt-40 pb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 text-center">
                <div className="error">
                  <h2>Entrez les caractéristiques du patient :</h2>
                  <Row>
                    <Col>
                      <ShopGender
                        location={location}
                        genders={["Men", "Women", "Unisex"]}
                        getSortParams={setGenderSortType}
                        setGenderSortValue={setGenderSortValue}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        isHome={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ShopIsMotorPref
                        location={location}
                        motors={["Oui", "Non"]}
                        getSortParams={setIsMotorSortType}
                        setGenderSortValue={setIsMotorSortValue}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        isHome={true}
                      />
                    </Col>
                  </Row>
                  {
                    currentQuery && currentQuery.isMotor && currentQuery.isMotor == "Oui" ?
                    <Row>
                      <Col>
                        <ShopMotorPref
                          location={location}
                          motors={["Aérien en cuisse", "Aérien en pied", "Terrien en cuisse", "Terrien en pied"]}
                          getSortParams={setMotorSortType}
                          setGenderSortValue={setMotorSortValue}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          isHome={true}
                        />
                      </Col>
                    </Row>
                    : null
                  }
                  
                  <Row>
                    <Col>
                      <ShopIsPain
                        location={location}
                        pains={["Oui", "Non"]}
                        getSortParams={setIsPainSortType}
                        setGenderSortValue={setIsPainSortValue}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        isHome={true}
                      />
                    </Col>
                  </Row>
                  {
                    currentQuery.isPain && currentQuery.isPain == "Oui" ?
                    <Row>
                      <Col>
                        <ShopPain
                          location={location}
                          pains={["Douleur pied-talon", "Douleur chaîne postérieure", "Douleur chaîne antérieure", "Douleur genou-hanche-dos"]}
                          getSortParams={setPainSortType}
                          setGenderSortValue={setPainSortValue}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          isHome={true}
                        />
                      </Col>
                    </Row>
                    : null
                  }
                  
                  {
                    currentShoeType && currentShoeType.length > 0 ?
                    <Row>
                      <Col>
                        <ShopShoeType
                          location={location}
                          types={currentShoeType}
                          getSortParams={setShoeTypeSortType}
                          setGenderSortValue={setShoeTypeSortValue}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          isHome={true}
                        />
                      </Col>
                    </Row>
                    : null
                  }

                  {
                    isPerformanceShoe ? 
                    <Row>
                      <Col>
                        <ShopStrideAnalysis
                          location={location}
                          types={["Foulée antérieure", "Foulée postérieure"]}
                          getSortParams={setStrideAnalysisSortType}
                          setGenderSortValue={setStrideAnalysisSortValue}
                          currentQuery={currentQuery}
                          setCurrentQuery={setCurrentQuery}
                          isHome={true}
                        />
                      </Col>
                    </Row>
                    : null 
                  }

                  <Row>
                    <Col>
                      <ShopShoeDistance
                        location={location}
                        distances={["Courte distance (jusqu'à 10km)", "Longue distance"]}
                        getSortParams={setDistanceSortType}
                        setGenderSortValue={setDistanceSortValue}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        isHome={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <ShopShoeUsage
                        location={location}
                        usages={["Running", "Trail"]}
                        getSortParams={setUsageSortType}
                        setGenderSortValue={setUsageSortValue}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        isHome={true}
                      />
                    </Col>
                  </Row>
                  
                  <Row style={{marginTop: "40px"}}>
                    <Col xs={12}>
                      {
                        currentQuery && 
                        ((currentQuery.motor && currentQuery.motor !== "" && currentQuery.isMotor && currentQuery.isMotor == "Oui") || currentQuery.isMotor && currentQuery.isMotor == "Non") && 
                        currentQuery.genders && currentQuery.genders !== "" && 
                        currentQuery.pains && currentQuery.pains !== "" && 
                        ((currentShoeType.length > 0 && currentQuery.types && currentQuery.types !== "") ||
                        (currentShoeType.length == 0 && !currentQuery.types))?
                        <Button
                          type="submit"
                          fullWidth
                          onClick={() => handleSearch(currentQuery)}
                          variant="contained"
                          style={{height: "100%", width: "100%", padding: "10px", backgroundColor: "black", color: "white"}}
                      >
                          Rechercher
                      </Button>
                        : 
                          <Button
                            type="submit"
                            disabled
                            fullWidth
                            variant="contained"
                            style={{height: "100%", width: "100%", padding: "10px", backgroundColor: "black", color: "white"}}
                        >
                            Rechercher
                        </Button>
                       }
                      
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

ProResearchHomePage.propTypes = {
  location: PropTypes.object
};

export default ProResearchHomePage;


