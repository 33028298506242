import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Rating from "./sub-components/ProductRating";
import ProductModal from "./ProductModal";
import {getHexaColorFromName, slugify} from "../../helpers/product"
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import {useTranslation} from "react-i18next";
import {isMobileOnly, isTablet} from "react-device-detect";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FaRoad, FaWeightHanging } from "react-icons/fa";
import { GiMountainCave, GiRunningShoe } from "react-icons/gi";
import { MdDensitySmall } from "react-icons/md";
import { BsSuitHeart, BsSuitHeartFill } from "react-icons/bs";
import { setWithExpiry } from "../../pages/home/HomeFashionTwo";
import { TbBrandCarbon, TbBrandProducthunt } from "react-icons/tb";
import { GrRun } from "react-icons/gr";

const ProductGridListSingle = ({
  product,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass,
  avalaibleColors,
  setFavoriteProducts,
  favoriteProducts,
  index
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();
  const { t } = useTranslation()
  const [isFavoriteProduct, setIsFavoriteProduct] = useState(product.isFavoriteItem);

  const handleFavProduct = (favoriteProducts) => {
    let shortURL = product.shortURL
    if (product.shortURL.split("?") && product.shortURL.split("?").length > 1){
      shortURL = product.shortURL.split("?")[0]
    }

    if(!isFavoriteProduct){
      let currentFavProducts = []
      if(favoriteProducts){
         currentFavProducts = [...favoriteProducts]
      }
      
      if(favoriteProducts && favoriteProducts.length > 0){
        let notExists = true
        favoriteProducts.map(favProduct => {
          let favShortURL = favProduct.shortURL
          if (favProduct.shortURL.split("?") && product.shortURL.split("?").length > 1){
            favShortURL = favProduct.shortURL.split("?")[0]
          }
          if (product.headCategory == favProduct.headCategory && favShortURL == shortURL){
            notExists = false
          }
        })
        if(notExists){
          currentFavProducts.push(product)
          setFavoriteProducts(currentFavProducts)
          setWithExpiry("trc-pro-front-exported", currentFavProducts, 3600000)
        }
      } else {
        currentFavProducts.push(product)
        setFavoriteProducts(currentFavProducts)
        setWithExpiry("trc-pro-front-exported", currentFavProducts, 3600000 )
      }
    } else {
      var filtered = favoriteProducts.filter(function(el) { return el.shortURL != product.shortURL; }); 
      setFavoriteProducts(filtered)
      setWithExpiry("trc-pro-front-exported", filtered, 3600000 )
    }
    setIsFavoriteProduct(!isFavoriteProduct)
  } 

  useEffect(() => {
    let shortURL = product.shortURL
    if (product.shortURL.split("?") && product.shortURL.split("?").length > 1){
      shortURL = product.shortURL.split("?")[0]
    }

    if(favoriteProducts && favoriteProducts.length > 0){
      favoriteProducts.map(favProduct => {
        let favShortURL = favProduct.shortURL
          if (favProduct.shortURL.split("?") && product.shortURL.split("?").length > 1){
            favShortURL = favProduct.shortURL.split("?")[0]
          }
        if (product.headCategory == favProduct.headCategory && favShortURL == shortURL){
          setIsFavoriteProduct(true)
        }
      })
    }
  }, [product])

  const getUniqueColors = (declinations) => {
    let colors = []
    if (declinations && declinations.length > 0){
      declinations.map(declination => {
        colors.push(declination)
      })
      const filteredArray = colors.filter(function(ele , pos){
        return colors.indexOf(ele) == pos;
      })
      return filteredArray
    }

    return colors
  }

  const finalProductPrice = product.price;
  const discountedPrice = product.bestPrice;
  const finalDiscountedPrice = +(
      discountedPrice * currency.currencyRate
  ).toFixed(2);

  const discount = product.amount

  return (
    <Fragment>
      <div
        className={`col-xl-3 col-sm-6 ${
          sliderClassName ? sliderClassName : ""
        }`}
        style={{maxWidth: isMobileOnly ? "50%" : "100%", paddingRight: isTablet ? "5px" : index % 2 === 0 ? "5px" : "5px",
          paddingLeft: isTablet ? "5px" : index % 2 === 1 ? "5px" : "5px" }}
      >
        <div
          className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}
          onClick={() =>
              ReactPixel.track('ViewContent', {
                content_name: product.name,
                content_type: 'product',
                content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                content_ids: product.id,
                value: finalProductPrice !=0 ? finalProductPrice : product.price,
                currency: 'EUR',
              })
          }
        >
          <div className="product-img">
            <div style={{height: isMobileOnly ? "192px" : "100%",
              textAlign: "center",
              backgroundColor: "#f4f6f5"}}>
              <a href={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                <img
                    style={{width: "80% !important"}}
                    className="default-img"
                    src={product.firstImageUrl}
                    alt=""
                />
              </a>
            </div>

            {discount > 0 && discount !== 100 ? (
              <div className="product-img-badges">
                {discount > 0 && discount !== 100 ? (
                  <span className="purple">-{discount}%</span>
                ) : null}
              </div>
            ) : (
              ""
            )}

            {product.soonOutOfStock ? (
                  <div className="product-img-badges">
                    {
                      isMobileOnly ? 
<                     span className="purple" style={{backgroundColor : 'orange', fontSize: "11px", color : 'white',
                        marginTop : (discount > 0  && discount !== 100) ? "25px" : 0}}>Dernières tailles</span>
                      : <span className="purple" style={{backgroundColor : 'orange', color : 'white',
                      marginTop : (discount > 0  && discount !== 100) ? "25px" : 0}}>Dernières tailles disponibles</span>
                    }
                    
                  </div>
              ) : (
                  ""
              )}

              {product.outOfStock ? (
                  <div className="product-img-badges">
                    {
                      isMobileOnly ? 
<                     span className="purple" style={{backgroundColor : 'red', fontSize: "11px", color : 'white'}}>Rupture de stock</span>
                      : <span className="purple" style={{backgroundColor : 'red', color : 'white'}}>Rupture de stock</span>
                    }
                    
                  </div>
              ) : (
                  ""
              )} 


            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip">Ajouter à la sélection</Tooltip>}
            >
              <div 
              className="product-img-badges" 
              onClick={() => handleFavProduct(favoriteProducts)}
              style={{right:"inherit", top: "12px", left: "8px", cursor: "pointer"}}>
                <span className="purple" style={{background : 'none'}}>
                  {
                    isFavoriteProduct ?
                    <BsSuitHeartFill style={{color: "red"}} size={"1.8rem"}/>               
                    : 
                    <BsSuitHeart style={{color: "gray"}} size={"1.8rem"}/>               
                  }
                  </span>
              </div>
            </OverlayTrigger>

            

            {product.availableSoon && discount == 0 ?
                <div className="product-img-badges">
                  <span className="purple" style={{backgroundColor : 'red'}}>Bientôt disponible</span>
                </div>
                : null }

            <div className="product-action">
              <div className="pro-same-action pro-cart">
                {product.colors ? (
                    <div className="pro-details-size-color" style={{textAlign: "center"}}>
                      <div className="pro-details-color-wrap">
                        <div className="pro-details-color-content">
                          {getUniqueColors(product.colors).map((single, key) => {
                            return (
                                <label
                                    className={`pro-details-color-content--single`}
                                    style={
                                      {backgroundColor: getHexaColorFromName(single, avalaibleColors),
                                        border: '1px solid #333'
                                      }}
                                    key={key}
                                >
                                  <input
                                      type="radio"
                                      value={single}
                                      name="product-color"
                                  />
                                  <span className="checkmark"></span>
                                </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                ) : (
                    <button disabled className="active">
                      Indisponible
                    </button>
                )}
              </div>
            </div>
          </div>
          <div className="product-content-2">
            <a href={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
              <div
                  className={`title-price-wrap-2`}
              >
                <div className="price-2">
                  <Row>
                    <Col xs={10}>
                        {discount > 3 && discount !== 100 ? (
                          <Fragment>
                        <span style={{fontWeight: '600'}}>
                          {finalDiscountedPrice + currency.currencySymbol}
                        </span>
                            <span className="old" style={{color: 'grey', fontSize: '14px', fontWeight: '600'}}>
                          {finalProductPrice + currency.currencySymbol}
                        </span>
                          </Fragment>
                      ) : (
                          <span style={{fontWeight: '600'}}>
                          {finalProductPrice !=0 ? finalProductPrice + currency.currencySymbol : product.bestPrice !=0 ? product.bestPrice + currency.currencySymbol :  "0" + currency.currencySymbol}
                        </span>
                      )}
                    </Col>
                    {
                      product && (product.headCategory == "/products/shoes" ||product.headCategory == "/products/spikes") ?
                    <Col xs={2}>
                      {
                        product.shopType && product.shopType.indexOf("trail") > -1 ?
                          <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="button-tooltip">Chaussures de trail</Tooltip>}
                          >
                            <GiMountainCave size={"1.4rem"}/>
                          </OverlayTrigger>
                        : product.shopType && product.shopType.indexOf("running") > -1 ?
                          <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="button-tooltip">Chaussures de running</Tooltip>}
                          >
                          <FaRoad  size={"1.4rem"}/>
                        </OverlayTrigger> : 
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip">Pointes d'athlétisme</Tooltip>}
                        >
                        <GrRun  size={"1.4rem"}/>
                      </OverlayTrigger>
                      }
                    </Col> : null}
                  </Row>
                  
                </div>
                <h3>
                  <Link to={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                    {product.name}
                  </Link>
                </h3>
                <div>
                  <a href={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                    <span style={{color: "grey", fontWeight: 500, fontSize: "13px"}}>{t(product.gender.toLowerCase())}</span>
                  </a>
                </div>
              </div>
              {
                product.weigh  || product.drop || product.cushioningType ?
                  <Row style={{marginBottom: "15px"}}>
                      {
                        product.weigh !== "" ?
                          <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="button-tooltip">Poids</Tooltip>}
                          >
                            <Col xs={isMobileOnly ? 6 : 4}><div style={{backgroundColor: "rgb(233 237 235)", borderRadius: "5px", textAlign: "center"}}><FaWeightHanging style={{fontSize:"13px"}}/> : <span style={{fontSize:"13px"}}>{product.weigh}g</span></div></Col>
                          </OverlayTrigger>
                        : null
                      }
                      {
                        product.drop !== "" ?
                          <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="button-tooltip">Drop</Tooltip>}
                          >
                            <Col xs={isMobileOnly ? 6 : 4}><div style={{backgroundColor: "rgb(233 237 235)", borderRadius: "5px", textAlign: "center"}}><GiRunningShoe/> : <span style={{fontSize:"13px"}}>{product.drop}mm</span></div></Col>
                          </OverlayTrigger>
                        : null
                      }
                      {
                        product.cushioningType !== "" ?
                          <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="button-tooltip">Type d'amorti</Tooltip>}
                          >
                            <Col xs={isMobileOnly ? 6 : 4}><div style={{marginTop: isMobileOnly ? "10px" : 0 , backgroundColor: "rgb(233 237 235)", borderRadius: "5px", textAlign: "center"}}><MdDensitySmall/> : <span style={{fontSize:"13px"}}>{product.cushioningType} {product.isCarbonPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque carbone</Tooltip>}><TbBrandCarbon size={"1rem"}/></OverlayTrigger> : null}{product.isPlasticPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque Plastique</Tooltip>}><TbBrandProducthunt size={"1rem"}/></OverlayTrigger> : null}</span></div></Col>
                          
                          </OverlayTrigger>
                        : null
                      }
                  </Row>
                : null
              }
            </a>
            {((product.posts && product.posts.length > 0) || product.trainingApproved || product.raceApproved) ?
                <div className="pro-wishlist-2">
                  <button
                  >
                    {product.posts && product.posts.length > 0 ?
                        <span>
                          <Link to={process.env.PUBLIC_URL + '/post/' + product.posts[0]} style={{margin: '0'}}>
                             <i title={"Ce produit a été testé par nos équipes : clique ici pour lire le compte rendu !"}
                                style={{color: 'blue'}}
                                className="fas fa-feather-alt"
                             ></i>&nbsp;
                          </Link>
                        </span>
                        :
                        null}
                    {product.trainingApproved ?
                        <span>
                          <i title={"Ce produit dispose du label « training » certifié par The Running Collective. " +
                          "Pour détenir ce label, un produit doit remplir au moins un des critères suivants : " +
                          "- durée de vie supérieure à 600km - historicité du modèle (nombre de versions > 5) " +
                          "- Confort de la paire testé par nos équipes"} style={{color: 'green'}} className="fas fa-running"></i>
                        </span>
                        : null}
                    {product.raceApproved ?
                        <span>
                          <i title={"Ce produit dispose du label « ultra performance » certifié par The Running Collective. " +
                          "Cela signifie qu’il a été porté lors d’un record du monde ou d’un titre mondial/olympique. " +
                          "Seuls quelques produits disposent de ce label !"} style={{color: 'gold'}} className="fas fa-trophy"></i>&nbsp;
                        </span>
                        : null}
                  </button>
                </div>
                :
                null
            }
          </div>
        </div>
        <div className="shop-list-wrap mb-30">
          <div className="row">
            <div className="col-xl-4 col-md-5 col-sm-6">
              <div className="product-list-image-wrap">
                <div className="product-img">
                  <Link to={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                    <img
                      className="default-img img-fluid"
                      src={process.env.PUBLIC_URL + product.firstImageUrl}
                      alt=""
                    />
                  </Link>
                  {discount > 0 && discount !== 100 ? (
                    <div className="product-img-badges">
                      {discount > 0 && discount !== 100 ? (
                        <span className="purple">-{discount}%</span>
                      ) : (
                        ""
                      )}
                      {product.new ? <span className="purple">New</span> : ""}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-7 col-sm-6">
              <div className="shop-list-content">
                <h3>
                  <Link to={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                    {product.name}
                  </Link>
                </h3>
                <div className="product-list-price">
                  {discount > 0 && discount !== 100 ? (
                    <Fragment>
                      <span>
                        {finalDiscountedPrice + currency.currencySymbol}
                      </span>{" "}
                      <span className="old">
                        {finalProductPrice + currency.currencySymbol}
                      </span>
                    </Fragment>
                  ) : (
                    <span>{finalProductPrice + currency.currencySymbol} </span>
                  )}
                </div>
                {product.rating && product.rating > 0 ? (
                  <div className="rating-review">
                    <div className="product-list-rating">
                      <Rating ratingValue={product.rating} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {product.shortDescription ? (
                  <p>{product.shortDescription}</p>
                ) : (
                  ""
                )}

                <div className="shop-list-actions d-flex align-items-center">
                  <div className="shop-list-btn btn-hover">
                    {product.affiliateLink ? (
                      <a
                        href={product.affiliateLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        Buy now{" "}
                      </a>
                    ) : product.variation && product.variation.length >= 1 ? (
                      <Link
                        to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                      >
                        Select Option
                      </Link>
                    ) : product.stock && product.stock > 0 ? (
                      <button
                        onClick={() => addToCart(product, addToast)}
                        className={
                          cartItem !== undefined && cartItem.quantity > 0
                            ? "active"
                            : ""
                        }
                        disabled={
                          cartItem !== undefined && cartItem.quantity > 0
                        }
                        title={
                          cartItem !== undefined
                            ? "Added to cart"
                            : "Add to cart"
                        }
                      >
                        {" "}
                        <i className="pe-7s-cart"></i>{" "}
                        {cartItem !== undefined && cartItem.quantity > 0
                          ? "Added"
                          : "Add to cart"}
                      </button>
                    ) : (
                      <button disabled className="active">
                        Out of Stock
                      </button>
                    )}
                  </div>

                  <div className="shop-list-wishlist ml-10">
                    <button
                      className={wishlistItem !== undefined ? "active" : ""}
                      disabled={wishlistItem !== undefined}
                      title={
                        wishlistItem !== undefined
                          ? "Added to wishlist"
                          : "Add to wishlist"
                      }
                      onClick={() => addToWishlist(product, addToast)}
                    >
                      <i className="pe-7s-like" />
                    </button>
                  </div>
                  <div className="shop-list-compare ml-10">
                    <button
                      className={compareItem !== undefined ? "active" : ""}
                      disabled={compareItem !== undefined}
                      title={
                        compareItem !== undefined
                          ? "Added to compare"
                          : "Add to compare"
                      }
                      onClick={() => addToCompare(product, addToast)}
                    >
                      <i className="pe-7s-shuffle" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedprice={discountedPrice}
        finalproductprice={finalProductPrice}
        finaldiscountedprice={finalDiscountedPrice}
        cartitem={cartItem}
        wishlistitem={wishlistItem}
        compareitem={compareItem}
        addtocart={addToCart}
        addtowishlist={addToWishlist}
        addtocompare={addToCompare}
        addtoast={addToast}
      />
    </Fragment>
  );
};

ProductGridListSingle.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object,
  avalaibleColors: PropTypes.any,
  favoriteProducts: PropTypes.any,
  setFavoriteProducts: PropTypes.func,
};

export default ProductGridListSingle;
